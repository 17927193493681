<template>
  <div>
    <page-header icon="fa fa-dice" :title="title" :links="getLinks()"></page-header>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div ref="printMe" v-if="!isLoading(loadingName) && canView">
      <b-row>
        <b-col>
          <div ref="excelMe">
            <x-table
              id="articles"
              :columns="columns"
              :items="items"
              :show-counter="true"
              verbose-name="exemplaire"
            >
            </x-table>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif'
import XTable from '@/components/Controls/Table/Table'
import PageHeader from '@/components/Layout/PageHeader'
import { BackendMixin } from '@/mixins/backend'
import router from '@/router'
import { makeStoreArticle, makeStoreItem } from '@/types/store'
import { BackendApi, openDocument } from '@/utils/http'
import { slugify } from '@/utils/strings'

export default {
  name: 'StoreItems',
  mixins: [BackendMixin],
  components: {
    PageHeader,
    LoadingGif,
    XTable,
  },
  data() {
    return {
      loadingName: 'store-items',
      items: [],
      columns: [],
    }
  },
  mounted() {
    this.columns = this.getColumns()
    this.loadStoreItems()
  },
  watch: {
  },
  computed: {
    canView() {
      return this.hasPerm('store.view_item')
    },
    title() {
      return 'Inventaire Ludothèque'
    },
  },
  methods: {
    ...mapActions(['addError']),
    ...mapMutations(['startLoading', 'endLoading']),
    getColumns() {
      const columns = [
        {
          name: 'name',
          label: 'Nom',
          onClick: item => {
            router.push(
              { name: 'store-article-detail', params: { articleId: '' + item.id, }, }
            )
          },
        },
        {
          name: 'classification',
          label: 'Code ESAR',
        },
        {
          name: 'type',
          label: 'Type',
        },
        {
          name: 'reference',
          label: 'Référence',
        },
        {
          name: 'location',
          label: 'Emplacement',
        },
        {
          name: 'status',
          label: 'État',
        },
        {
          name: 'isAvailable',
          label: 'Disponible',
        },
        {
          name: 'isBorrowed',
          label: 'Emprunt en cours',
        }
      ]
      return columns
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = 'liste-' + slugify('Ludothèque')
      const docContent = this.$refs.excelMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async printMe() {
      let docUrl = '/documents/standard/<key>/pdf/'
      const docSlug = 'liste-' + slugify('Ludothèque')
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    getLinks() {
      let links = [
        {
          id: 1,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'far fa-file-excel',
          cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
        },
        {
          id: 2,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
        }
      ]
      return links
    },
    async loadStoreItems() {
      this.startLoading(this.loadingName)
      const backendApi = new BackendApi('get', '/store/api/items/')
      try {
        let resp = await backendApi.callApi()
        this.items = resp.data.map(this.makeItem)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    makeItem(elt) {
      const item = makeStoreItem(elt)
      const article = makeStoreArticle(elt.article)
      const isBorrowed = elt['is_borrowed'] ? 'Oui' : 'Non'
      const isAvailable = item.isAvailable ? 'Oui' : 'Non'
      const hasItemLocation = item.location && item.location.id
      let location = article.location
      if (hasItemLocation) {
        location = item.location
      }
      return {
        id: article.id,
        name: article.name,
        reference: item.reference,
        location: location.name,
        isBorrowed: isBorrowed,
        isAvailable: isAvailable,
        status: item.status,
        classification: article.classification.name,
        type: article.type.name,
        article: article,
      }
    },
  },
}
</script>

<style scoped>
.load-all-articles {
  margin-top: 15px;
}
.selected-entity-filter {
  font-size: 20px;
  font-weight: bold;
  padding: 5px 10px;
  border: solid 1px #ccc;
  border-radius: 4px;
  background: #fbff8d;
  margin-bottom: 5px;
}
</style>
